import React, { useState, useEffect } from 'react';
import styled from "styled-components"
import SectionHeading from "../components/SectionHeading"
import LogoBlock from "../components/LogoBlock"
import Background from "../images/section-background.png"
import SectionParagraph from "../components/SectionParagraph"
import JobFilter from '../components/JobFilter';
import Accordion from '../components/Accordion';
import AccordionRow from '../components/AccordionRow'
import { AnchorLink } from "gatsby-plugin-anchor-links";
import HomeBannerSlider from "../components/HomeBannerSlider"
const BannerSection = styled.div`
    display:flex;
    flex-direction:row;
    width:100%;
    height:768px;
    position:relative;
    justify-content:flex-start;
    margin-bottom:50px;
    @media screen and (max-width: 991px) {
        flex-direction:column;
        height:auto;
    }
    @media screen and (max-width: 767px) {
        margin-bottom:0px;
    }
    @media screen and (max-width: 479px) {      
       
    }
    `;
const HomeSliderContainer = styled.div`
    width:50%;
    height:100%;  
    z-index:3;
    @media screen and (max-width: 991px) {
        width:100%;
    }
    @media screen and (max-width: 479px) {
        width:100%;
    }
`;

const BannerContentWrap = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:flex-start;
    width:50%;
    padding:0 2.5% 0 2.5%;;
    height:100%;
    @media screen and (max-width: 991px) {
      width:100%;
      margin-bottom:100px;
    }
    @media screen and (max-width: 479px) {
        margin-bottom:20px;
      }
`;
const Breadcrumb = styled.h1`
    font-family: Montserrat;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #2e3439;
    margin:20px 0 80px 0;
    @media screen and (max-width: 767px) {
        margin-bottom:10vh;
    }
    @media screen and (max-width: 479px) {
        margin:40px 0 5vh 0;
    }
`;
const BackSection = styled.div`
    position:absolute;
    top:100px;
    left:0%;
    height:100%;
    z-index:-1;
    display: flex;
    flex-direction: column;
    width: 70%;
    background-position:100% 0%;
    opacity:0.7;
    background-image:url(${Background});
    @media screen and (max-width: 479px) {
        width: 100%;
        background-position:35% 0%;
    }
`;
const AnchorTagWrap = styled.div`
    display:flex;
    align-items:flex-start;
    margin-top:0vh;
    width:100%;
    @media screen and (max-width: 767px) {
        flex-direction:column;
    }
`;
const AnchorTag = styled.div`
    font-family: Montserrat;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 3;
    letter-spacing: normal;
    text-align: left;
    color: #2e3439;
    margin-right:30px;
    opacity:0.6;
    transition:all 0.4s ease;
    &:hover{
        color: #3c57a1;
        opacity:1;
    }
    @media screen and (max-width: 767px) {
        margin-right:0px;
        font-size: 18px;
    }
`;
const BannerContentContainer = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:flex-start;
    width:100%;
    height:100%;
    @media screen and (max-width: 991px) {
      width:100%;
      margin-bottom:100px;
    }
    @media screen and (max-width: 479px) {
        margin-bottom:20px;
      }
`;
const JobSection = styled.div`
    display:flex;
    width:100%;
    flex-direction:column;
    align-items:flex-start;
    padding:0 2.5% 0 2.5%;
    margin-bottom:40px;
    @media screen and (max-width: 479px) {
        margin-top:40px;
      }
`;
const NoPositions = styled.h2`
    font-family: Montserrat;
    font-size: 25px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #3C57A1;
    margin-top:30px;
    @media screen and (max-width: 767px) {
        font-size: 18px;
      }
`;
const Careers = (props) => {
    const getCategories = (items) => {
        let categoryItems = items.map(item => {
            return item.documentTags.nodes[0].name
        })
        let uniqueCategories = new Set(categoryItems)
        let categories = Array.from(uniqueCategories)
        categories = ["Entire Portfolio", ...categories]
        return categories
    }
    const [categories, setCategories] = useState(getCategories(props.data.allWpJob.nodes));
    const [selectedCategory, setSelectedCategory] = useState('')
    useEffect(() => {
        setCategories(getCategories(props.data.allWpJob.nodes))
    }, [props.data.allWpJob.nodes]);
    const handleValueChange = category => {
        setSelectedCategory(category);
    }
    // const functionToFilterJobs = function(job){        
    //     return job.documentTags.nodes[0].name.toString().toLowerCase() ==selectedCategory.toString().toLowerCase();
    // }
    // const filtered = props.data.allWpJob.nodes.filter(functionToFilterJobs)
    const FilteredResults = (category) => {
        const query = [category]
        const filteredData =
            props.data.allWpJob.nodes.filter(job => {
                return (
                    job.documentTags.nodes[0].name.toString().toLowerCase().includes(query.toString().toLowerCase())
                )
            })
        return filteredData
    }
    const FilterAllExceptWHV = (category) => {
        const query = [category]
        const filteredData =
            props.data.allWpJob.nodes.filter(job => {
                return (
                    !job.documentTags.nodes[0].name.toString().toLowerCase().includes(query.toString().toLowerCase())
                )
            })
        return filteredData
    }
    return (
        <div>
            <BannerSection>
                <BannerContentWrap>
                    <LogoBlock></LogoBlock>
                    <Breadcrumb>Careers</Breadcrumb>
                    <BannerContentContainer>
                        <SectionHeading>We are looking for passion to solve critical problems in healthcare.</SectionHeading>
                        <SectionParagraph top={"40px"} mobileTop={"15px"}>We believe that the right team can help us achieve our vision. If you want to combine your skills with an organization that will push you to think, build, and grow, please apply to open positions below:</SectionParagraph>
                        <AnchorTagWrap>
                            <AnchorLink to="/career#whv-positions" className="anchor">
                                <AnchorTag>Work with us</AnchorTag>
                            </AnchorLink>
                            <AnchorLink to="/career#portfolio-positions" className="anchor">
                                <AnchorTag>Work with our Portfolio</AnchorTag>
                            </AnchorLink>
                        </AnchorTagWrap>
                    </BannerContentContainer>
                </BannerContentWrap>
                <HomeSliderContainer>
                    <HomeBannerSlider home={false} image={"Career.jpg"} />
                </HomeSliderContainer>

                <BackSection></BackSection>
            </BannerSection>
            <JobSection>
                {FilteredResults('WHV').length > 0 ?
                    <>
                        <SectionHeading id={"whv-positions"}>W Health Ventures Positions</SectionHeading>
                        {FilteredResults('WHV').map((job, index) => (
                            <AccordionRow companyName={false} background={"#efefef"} key={index} job={job} padding={"20px 30px"}></AccordionRow>
                        ))}
                    </>
                    : ''}
                <SectionHeading id={"portfolio-positions"} top={"80px"} mobileTop={"20px"}>Portfolio Positions</SectionHeading>
                {/* {FilterAllExceptWHV('WHV').length>0?
                <>
                    <JobFilter handleValueChange={handleValueChange} categories={categories}></JobFilter>
                    {selectedCategory==='Entire Portfolio'?
                        categories.map((category, index) => (
                            category!=='Entire Portfolio' && category!=='WHV'?     
                            FilteredResults(category).length>0?                   
                            <Accordion key={index} category={category} data={FilteredResults(category)}></Accordion>:''
                            :''
                        ))                    
                    :
                        FilteredResults(selectedCategory).length>0?  
                        <Accordion category={selectedCategory} data={FilteredResults(selectedCategory)}></Accordion>:''
                    }    
                </>
                : */}
                <NoPositions>Sorry, there are no positions available at this time</NoPositions>
                {/* } */}

            </JobSection>
        </div>
    );
};

export default Careers;