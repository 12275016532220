import React,{useState,useEffect,useRef } from 'react';
import styled from "styled-components"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { IoCloseSharp } from "@react-icons/all-files/io5/IoCloseSharp";
import AccordionRow from "../components/AccordionRow"

const AccordionWrap= styled.div`
    width: 100%;
    padding: 0px 30px 20px 30px;
    border-radius: 20px;
    background-color: #efefef;
    margin:20px 0 20px 0;
    cursor:pointer;
`;
const AccordionHeader = styled.div`
    margin:40px 0 20px 0;
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
`;
const Title = styled.h1`
    margin-bottom:0px;
    font-family: Montserrat;
    font-size: 35px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.63;
    letter-spacing: normal;
    text-align: left;
    color: #48494a;
`;
const Chevron = styled.div`
    font-size:20px;
    color:#48494a;
    width:28px;
    height:28px;
    border-radius:50%;
    background:${props=>props.openState?'#cbcbcb':'transparent'};
    display:flex;
    transition:all 0.5s ease;
    justify-content:center;
    align-items:center;
    box-shadow:${props=>props.openState?'0 8px 16px 0 rgba(0, 0, 0, 0.16)':'none'};
`;
const AccordionContentContainer= styled.div`
    overflow:hidden;
    width:100%;
    flex-direction:column;
    justify-content:flex-start;
    align-items:center;
    transition:all 0.5s ease;
    opacity:${props=>props.openState?'1':'0'};
`;

const Accordion = (props) => {
    const [openState, setOpenState] = useState(false);
    const [setHeight, setHeightState] = useState("0px");
    const content = useRef(null);
    const toggle= () =>{
        setOpenState(!openState)
        setHeightState(
            openState === true ? "0px" : `${content.current.scrollHeight}px`
          );
    }
    useEffect(() => {
        setOpenState(false);
      }, []);
    return (
        <AccordionWrap onClick={toggle}>
            <AccordionHeader>
                <Title>{props.category}</Title>                
                <Chevron openState={openState}>
                    {openState?<IoCloseSharp style={{fontSize:"25px"}}/>:<FontAwesomeIcon icon={faChevronRight}/>}                    
                </Chevron>
            </AccordionHeader>
            <AccordionContentContainer ref={content}  openState={openState} style={{ maxHeight: `${setHeight}` }}>
                    {props.data.map((job, index) => (                 
                        <AccordionRow key={index} job={job}></AccordionRow>
                    )) }
            </AccordionContentContainer>
        </AccordionWrap>
    );
};

export default Accordion;