import React from 'react';
import styled from "styled-components"
import { GrShare } from "@react-icons/all-files/gr/GrShare";
const Row = styled.a`
    text-decoration:none;
    width:100%;
    border-radius:15px;
    padding:${props => props.padding ? props.padding : '27px 35px'};
    margin: 20px 0 20px 0;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
    position:relative;
    background:${props => props.background ? props.background : '#fff'};
    @media screen and (max-width: 479px) {
        flex-direction:column;
        height:auto;
        justify-content:center;
        padding:10px 15px 10px 15px;
        align-items:flex-start;
    }
`;

const RowText = styled.h1`
    margin:0px;
    font-family: Montserrat;
    font-size: 25px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 30px;
    letter-spacing: normal;
    text-align: left;
    color: #2e3439;
    width:45%;
    @media screen and (max-width: 479px) {
        width:auto;
        margin-top:${props => props.location ? '0px' : '10px'};
        margin-bottom:10px;
       font-size:${props => props.location ? "14px" : "18px"};
    }
`;
const FileLink = styled.div`
    width:10%;
    text-decoration:none;
    outline:none;
    margin:0px;
    font-size:25px;
    color:#000;
    @media screen and (max-width: 479px) {
        width:auto;
        position:absolute;
        top:auto;
        left:auto;
        right:15px;
        font-size:20px;
        bottom:20px;
    }
`;
const AccordionRow = (props) => {
    return (
        <Row background={props.background} padding={props.padding} href={props.job.jobFields.jd.mediaItemUrl} target="_blank" rel="noreferrer">
            <RowText>{props.job.title}</RowText>
            {!props.companyName ?
                ''
                :
                <RowText>{props.job.jobFields.company}</RowText>
            }
            <RowText location={true}>{props.job.jobFields.location}</RowText>
            <FileLink>
                <GrShare />
            </FileLink>
        </Row>
    );
};

export default AccordionRow;