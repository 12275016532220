import React,{useEffect,useState} from 'react';
import styled from "styled-components"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
const FilterWrap=styled.div`
    display:flex;
    position:relative;
    width:380px;
    margin-bottom:40px;
    margin-top:30px;
    @media screen and (max-width: 479px) {
        width:100%;
    }
`;
const InputRow = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
    width:100%;
    height:45px;
    align-items:center;
    padding:0 10px 0 10px;
    border-radius: 5px;
    border: solid 1px ${props => props.openState? 'rgba(46, 52, 57, 1)':'rgba(46, 52, 57, 0.4)'};
    outline:none;    
    transition:all 0.5s ease;
    position:relative;
`;
const FilterTag = styled.div`
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    transition:all 0.5s ease;
    color: ${props => props.openState? 'rgba(46, 52, 57, 1)':'rgba(46, 52, 57, 0.8)'};
`;
const Dropdown = styled.div`
    overflow:hidden;
    width:100%;
    position:absolute;
    z-index:20;
    left:0;
    top:45px;
    height:auto;
    max-height:${props => props.openState? '500px':'0px'};
    transition:all 0.5s ease;
    background-color: #fff;
    border: none;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    border-radius:5px;
    & ul{
        margin:0;
    }
    & li{
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: left;
        list-style:none;
        margin-bottom:0px;
        color: rgba(46, 52, 57,0.8);
        height:45px;
        padding:0 10px 0 10px;
        display:flex;
        align-items:center;
        &:hover{
            color: rgba(46, 52, 57,1);
            background-color: #f4f4f4;
        }
    }
`;
const Caret = styled.div`
    font-size:15px;
    color: ${props => props.openState? 'rgba(46, 52, 57, 0.8)':'rgba(46, 52, 57, 0.4)'};
    transition:all 0.3s ease;
    transform:${props => props.openState? 'rotate(90deg)':'rotate(0deg)'};
`;
const Label=styled.div`
    position:absolute;
    top: -7px;
    left: 25px;
    right:auto;
    bottom:auto;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 15px;
    letter-spacing: normal;
    text-align: left;
    transition:all 0.5s ease;
    color: ${props => props.openState? 'rgba(46, 52, 57, 1)':'rgba(46, 52, 57, 0.6)'};
    padding:0 4px 0 4px;
    background:#fff;
`;
const JobFilter = (props) => {
    const [openState, setOpenState] = useState(false);
    const [val, setVal] = useState(props.categories[0]);
    const toggle= (props) =>{
        setOpenState(!openState)
    }
    useEffect(() => {
        setOpenState(false);
      }, [val]);
    props.handleValueChange(val);
    return (
        <FilterWrap>
            <InputRow onClick={toggle} openState={openState}>
                <FilterTag openState={openState}>{val}</FilterTag>
                <Caret openState={openState}><FontAwesomeIcon icon={faChevronRight}/></Caret>
                <Label openState={openState}>Filter by</Label>
            </InputRow>
            <Dropdown openState={openState}>
                <ul>
                    {props.categories.map((category, index) => (
                        category!=='WHV'?
                        <li key={index} onClick={() => setVal(category)}>{category}</li>:''
                    ))}
                </ul>
            </Dropdown>
        </FilterWrap>
    );
};

export default JobFilter;