import * as React from "react"
import { graphql, StaticQuery } from "gatsby";
import Layout from "../components/layout"
import Seo from "../components/seo"
import Careers from "../views/Careers"
import Favicon from "../images/w-health-favicon.png"
const Career = (props) => (
  <StaticQuery
    query={graphql`
      query {
        allWpJob {
            nodes {
              title
              jobFields {
                company
                location
                jd {
                  mediaItemUrl
                }
              }
              documentTags {
                nodes {
                  slug
                  name
                }
              }
            }
          }
        }
      `}

    render={data => (
      <Layout>
        <Seo title="Careers" url="https://whealthventures.com/career" image={Favicon} />
        <Careers data={data}></Careers>
      </Layout>
    )}
  />

)
export default Career
